import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

interface IProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
  pageContext: {
    locale: "mn-mn" | "en-us";
  };
}

const NotFoundPage = (props: IProps) => {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout
      location={props.location}
      title={siteTitle}
      pageContext={props.pageContext}
    >
      <SEO title="404: Not Found" />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
